<template>
  <div v-if="showModal" id="logout" style="display:block;" class="modal fade show">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="modal-title">{{$t('modalLogout.title')}}</h5>
            <div class="divider-wrap col-12">
              <div class="divider-middle mx-auto"></div>
            </div>
            <div class="col text-center"> 
              <button @click="closeModalLogout()" type="submit" class="btn btn-secondary btn-modal rounded-pill col-md-5 logout">{{$t('modalLogout.button2')}}</button>
              <button @click="SignOut()" type="submit" class="btn btn-primary btn-modal rounded-pill col-md-5 logout">{{$t('modalLogout.button1')}}</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { PublicClientApplication } from '@azure/msal-browser';


export default {
  name: 'ModalLogout',
  components: {
  },
//  props: {
//    showModalLogout: Boolean
//  },
  data() {
    return {
      showModal: true,
    }
  },
  computed: {
    ...mapState([
      'msalConfig',
      'enviroments',
    ]),
  },
  watch: {
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(this.msalConfig.authApp);
  },
  mounted() {
//    this.showModal = this.showModalLogout;
    if(this.showModal) document.body.style.overflowY = "hidden";
  },
  methods: {

    async SignOut() {

      this.$store.commit('msalConfig/setAccount', {});    
      sessionStorage.clear()
      localStorage.clear()
  
      await this.$msalInstance.logout({}).then(() => {
      })
      .catch(error => {
        console.error(error);
        this.serviceError = true;
        this.serviceErrorMsg =  `ERROR:: during logout: ${error}`;
      });
    },

    closeModalLogout() {
      this.showModal = false;
      document.body.style.overflowY = "auto";
      this.$emit('closeModalLogout', true);
    }
  }
}
</script>

<style>
.modal {
  display: inherit;
}
</style>