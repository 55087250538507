<template>
    <div v-if="showModal" id="aceptar_condiciones" class="modal fade" :class="showModalConsent?'show':'in'">
        <form id="consentsForm" @submit.prevent="submitFirstConsent()" :data-form-page-id="consent_form_id">
            <input v-model="consents.email" type="email" style="display:none" name="email">
            <div class="modal-dialog-broad" role="document">
                <div class="modal-content">
                    <div class="modal-body modal-body-broad">
                        <h5 class="modal-title">{{$t('modalFirstConsent.title')}}</h5>
                        <div class="divider-wrap col-12">
                            <div class="divider-middle mx-auto"></div>
                        </div>
                        <p class="modal-text mx-auto">{{$t('modalFirstConsent.subtitle')}}<br />{{$t('modalFirstConsent.subtitle2')}}</p>
                        <img src="@/assets/img/icon-agreement.svg" alt="" class="mx-auto icon-modal">
                        <div class="">
                            <!-- <div v-for="(consent, index) in consents" :key="index" class="form-check">
                              <label class="container form-check-label">
                                <span v-if="$i18n.locale === 'en'">{{$t('modalFirstConsent.consentOne')}}</span>
                                <span v-if="$i18n.locale === 'es'">{{$t('modalFirstConsent.consentOne')}}</span> - {{consent.name}}
                                <input v-model="consent.accepted" type="checkbox">
                                <span class="checkmark"></span>
                              </label>
                            </div> -->
                            <div class="form-check">
                                <label class="container form-check-label" v-html="$t('modalFirstConsent.consentOne')"></label>
                                <div class="tooltip-error" v-if="consentOneRequired">
                                    <div class="arrow"></div>
                                    <span class="tooltip-text">{{$t('modalFirstConsent.required')}}</span>
                                </div>
                            </div>
                            <div class="form-check">
                                <label class="container form-check-label" v-html="$t('modalFirstConsent.consentTwo')"></label>
                            </div>
                        </div>
                        <div class="col text-center">
                            <button type="submit" class="btn btn-primary rounded-pill btn-modal">{{$t('modalFirstConsent.button')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <ModalLoading v-if="showModalLoading" :show-modal-loading="showModalLoading" />
    <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" :literal="serviceErrorMsg" />
</template>

<script>

    import ModalServiceError from '@/components/ModalServiceError.vue'
    import ModalLoading from '@/components/ModalLoading.vue'
    import { mapState } from 'vuex'

    export default {
        name: 'modalFirstConsent',
        components: {
            ModalServiceError,
            ModalLoading
        },
        props: {
            showModalConsent: Boolean
        },
        data() {
            return {
                showModal: false,
                showModalLoading: false,
                consents: {
                    email: null,
                    consentOne: {
                        name: null,
                        consent_id: null,
                        accepted: false
                    },
                    consentTwo: {
                        name: null,
                        consent_id: null,
                        accepted: false
                    }
                },
                consentOneRequired: false,
                serviceError: false,
                serviceErrorMsg: null
            }
        },
        computed: {
            ...mapState([
                'enviroments',
                'msalConfig',
                'consent_form_id',
                'lockApply'
            ]),
            account() {
                return Object.keys(this.msalConfig.account).length !== 0 ? this.msalConfig.account : undefined;
            },
            validateConsentOne() {
                return this.consents.consentOne.accepted;
            }
        },
        watch: {
            validateConsentOne(newValue) {
                if (newValue) {
                    this.consentOneRequired = false;
                } else {
                    this.consentOneRequired = true;
                }
            }
        },
        mounted() {
            document.querySelector('.carousel').classList.add('hidden');
            //    document.querySelector('.carousel_2').classList.add('hidden');

            if ((this.account.username === null) || (this.account.username === '')) {
                console.log("ERROR:: this.account.username empty");
                this.serviceError = true;
                this.serviceErrorMsg = "ERROR:: FIRST CONSENT this.account.username empty";
            }
            else {
                this.showModal = this.showModalConsent;
                this.consents.email = this.account.username;
                if (this.showModal) {
                    document.body.style.overflowY = "hidden";
                }
            }
        },
        methods: {
            submitFirstConsent() {
                //this.$router.push('/?email=prueba2%40prueba.com&consent1=on&consent2=on')

                if (!document.querySelector('#consent1').checked) {
                    this.consentOneRequired = true;
                    return
                }
                else {
                    this.consents.consentOne.accepted = true;
                }

                if (document.querySelector('#consent2').checked) {
                    this.consents.consentTwo.accepted = true;
                }
                // enviamos el tracking
                let form = document.querySelector('#consentsForm');
                let res = window.MsCrmMkt.MsCrmFormLoader.sendFormCaptureToCrm(form);

                console.log(res);

                document.body.style.overflowY = "auto";
                this.showModal = false;
                this.showModalLoading = true;
                this.$emit('closeModalConsent', true);

                return true;
            },
            modalErrorClose() { this.serviceError = false },
            openModal() { this.showModal = true }
        }
    }
</script>

<style>
    .fade:not(.show) {
        display: none;
    }

    .fade.show {
        display: block;
    }
</style>