<template>
    <ModalFirstConsent ref="modal1Consent" id="modalFirstConsent" v-if="showModalConsent" :show-modal-consent="showModalConsent" @close-modal-consent="modalConsentClose" />
    <ModalSelectChild v-if="showModalChild" :show-modal-child="showModalChild" :childs="childs" />
    <ModalLoading v-if="showModalLoading" :show-modal-loading="showModalLoading" />
    <div class="top-bar-bg">
        <nav class="navbar sticky-top navbar-light navbar-expand-lg navbar-bg top-bar mx-auto not-logged">
            <a class="navbar-brand" @click="goHome">
                <img src="@/assets/img/desktop-logo.svg" width="318" height="44" alt="" class="d-none d-md-block">
                <img src="@/assets/img/mobile-logo.svg" width="217" height="30" alt="" class="d-block d-md-none d-lg-none d-xl-none">
            </a>
            <span class="navbar-text top-bar-texto d-none d-lg-block" v-html="$t('header-bar.admissionRequest')"></span>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav col-auto ml-auto align-items-center d-none d-sm-none d-md-none d-lg-flex ">
                    <!--      <li class="nav-item ">
                                <a class="nav-link" @click="langSelector(lang)" href="#">
                                  <img src="@/assets/img/icon-lang-navbar.svg" alt="">
                                  <span v-if="lang === 'en'" class="lang">{{$t('header-bar.english')}}</span>
                                  <span v-if="lang === 'es'" class="lang">{{$t('header-bar.spanish')}}</span>
                                </a>
                              </li>-->
                    <li v-if="isHome" class="nav-item register-btn">
                        <a @click="SignIn" class="nav-link" href="#">
                            <button type="button" class="btn btn-primary btn-login rounded-pill">{{$t('header.button')}}</button>
                        </a>
                    </li>
                    <li v-if="!isHome && account" class="nav-item nav-candidato">
                        <div class="nav-link">
                            <div class="row">
                                <div class="col-lg-2">
                                    <img src="@/assets/img/icon-candidato-navbar.svg" alt="" class="">
                                </div>
                                <div class="col-lg-10 two-lines row">
                                    <div class=" col-xl-12">
                                        <p class="candidato">{{$t('header-bar.candidate')}}</p>
                                    </div>
                                    <div class="col-xl-12">
                                        <p class="candidato-name ">{{ account.username }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li v-if="!isHome && account" class="nav-item ">
                        <a class="nav-link" @click="SignOut()" href="#">
                            <img src="@/assets/img/logout.svg" alt="" class="ico-logout">
                            <span class="lang">{{$t('header-bar.logout')}}</span>
                        </a>
                    </li>
                    <li class="nav-item get-help">
                        <a class="nav-link" href="#">
                            <img src="@/assets/img/icon-help-navbar.svg" alt="" class="">
                            <span class="help">AYUDA</span>
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav col-auto ml-auto align-items-center d-lg-none navbar-menu-mobile">
                    <!--       <li class="nav-item nav-lang">
                                <a class="nav-link"  href="#" @click="langSelector(lang)">
                                 <span v-if="lang === 'en'" class="lang">{{$t('header-bar.englishMobile')}}</span>
                                 <span v-if="lang === 'es'" class="lang">{{$t('header-bar.spanishMobile')}}</span>
                                </a>
                              </li>-->
                    <li class="nav-item nav-home">
                        <a class="nav-link" href="/">
                            {{$t('header-bar.home')}}
                        </a>
                    </li>
                    <li v-if="account" class="nav-item nav-home">
                        <a class="nav-link" href="#" @click="SignOut()">
                            {{$t('header-bar.logout')}}
                        </a>
                    </li>
                    <div class="img-menu-mobile">
                        <img src="@/assets/img/img-logo-menu-mobile.png" alt="">
                    </div>
                </ul>
            </div>
        </nav>
    </div>
    <ModalExpireToken v-if="expireToken" />
    <ModalLogout v-if="requireLogout" @close-modal-logout="modalLogoutClose" />
    <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" :literal="serviceErrorMsg" :titleError="titleError" />
</template>

<script>

    import ModalFirstConsent from '@/components/ModalFirstConsent.vue'
    import ModalSelectChild from '@/components/ModalSelectChild.vue'
    import ModalExpireToken from '@/components/ModalExpireToken.vue'
    import ModalServiceError from '@/components/ModalServiceError.vue'
    import ModalLogout from '@/components/ModalLogout.vue'
    import ModalLoading from '@/components/ModalLoading.vue'
    import { PublicClientApplication } from '@azure/msal-browser';
    import { mapState } from 'vuex'

    export default {
        name: 'HeaderBar',
        components: {
            ModalFirstConsent,
            ModalSelectChild,
            ModalExpireToken,
            ModalServiceError,
            ModalLogout,
            ModalLoading
        },
        props: {
            isHome: Boolean
        },
        data() {
            return {
                signin: 'https://microsoft.com',
                showModalChild: false,
                showModalConsent: false,
                childs: [],
                expireToken: false,
                serviceError: false,
                titleError: false,
                serviceErrorMsg: null,
                requireLogout: false,
                showModalLoading: false,
            };
        },
        computed: {
            ...mapState([
                'msalConfig',
                'enviroments',
                'BasicInformation',
                'AcademicResults',
                'VideoPresentation',
                'RecommendationsTeamworks',
                'lockApply',
                'step'
            ]),
            lang() {
                return this.$i18n.locale === 'en' ? 'es' : 'en';
            },
            account() {
                if(this.msalConfig.account!=null)
                return Object.keys(this.msalConfig.account).length !== 0 ? this.msalConfig.account : undefined
                else
                return undefined;
            },
            accessToken() {
                if(this.msalConfig.accessToken!=null)
                return Object.keys(this.msalConfig.accessToken).length !== 0 ? this.msalConfig.accessToken : undefined
                else
                return undefined;
            }

        },
        async created() {
            this.$msalInstance = new PublicClientApplication(this.msalConfig.authApp);
        },
        mounted() {
            if (localStorage.getItem('account') != null) {
                this.$store.commit('msalConfig/setAccount', JSON.parse(localStorage.getItem('account')));
            }

            if (localStorage.getItem('accessToken') != null) {
                this.$store.commit('msalConfig/setAccessToken', JSON.parse(localStorage.getItem('accessToken')));
            }
        },
        methods: {
            async SignIn() {

                if (!this.lockApply) {
                    this.lockApply = true;
                    this.$store.commit('setLock', true);
                    document.body.style.overflowY = "hidden";


                    await this.$msalInstance.loginPopup(this.msalConfig.loginRequest).then(response => {
                        //const accounts = this.$msalInstance.getAllAccounts();
                        if ((response.accessToken === null) || (response.accessToken == '')) {
                            console.log("ERROR:: response.accessToken empty");
                            this.serviceError = true;
                            this.serviceErrorMsg = "ERROR:: response.accessToken empty";
                        }
                        else if ((response.account === null) || (response.account == '')) {
                            console.log("ERROR:: response.accessToken empty");
                            this.serviceError = true;
                            this.serviceErrorMsg = "ERROR:: response.accessToken empty";
                        }
                        else {
                            this.showModalLoading = true;
                            this.$store.commit('msalConfig/setAccessToken', response.accessToken);
                            this.$store.commit('msalConfig/setAccount', response.account);
                            localStorage.setItem("accesstoken", JSON.stringify(response.accessToken));
                            localStorage.setItem("account", JSON.stringify(response.account));
                            this.getFirstConsent();
                        }
                    }).catch(error => {
                        console.log('para cerrar modal');
                        console.log(`error during authentication: ${error}`);
                        document.body.style.overflowY = "auto";
                        this.showModalLoading = false;
                        this.$store.commit('setLock', false);
                    });
                }
            },
            SignOut() {
                this.requireLogout = true;
                /*      await this.$msalInstance.logout({}).then(() => {
                      })
                      .catch(error => {
                        console.error(error);
                        this.serviceError = true;
                        this.serviceErrorMsg =  `ERROR:: during logout: ${error}`;
                      });*/
            },
            langSelector(lang) {
                if (this.$i18n.locale !== lang) {
                    this.$i18n.locale = lang;
                }
            },
            getFirstConsent() {
                //El email se cambiara por el token cuando llegue el momento
                this.axios.get(`${this.enviroments.urlDev}/FirstConsent?token=${this.accessToken}`).then(response => {
                    if ((response.data === null) || (response.data === '')) {
                        console.log("ERROR:: response.data empty");
                        this.serviceError = true;
                        this.serviceErrorMsg = "ERROR:: FIRST CONSENT response.data empty";
                    }
                    else if ((response.data.status === null) || (response.data.status === '')) {
                        console.log("ERROR:: response.data.status empty");
                        this.serviceError = true;
                        this.serviceErrorMsg = "ERROR:: FIRST CONSENT response.data.status empty";
                    }
                    else if (response.data.status === 3) {
                        console.log("ERROR:: response.data.status maintenance");
                        this.serviceError = true;
                        this.titleError = true;
                        this.serviceErrorMsg = "We are sorry, the portal currently under maintenance. Please come back later";
                    }
                    else if ((response.data.level_consent === null) || (response.data.level_consent === '')) {
                        console.log("ERROR:: response.data.level_consent empty");
                        this.serviceError = true;
                        this.serviceErrorMsg = "ERROR:: FIRST CONSENT response.data.level_consent empty";
                    }
                    else {
                        this.showModalLoading = true;
                        if (response.data.status === 1) {
                            this.serviceError = true;
                            this.serviceErrorMsg = response.data.message;
                            console.log(response.data.message);
                        } else if (response.data.status === 2) {
                            this.expireToken = true;
                        }
                        else {
                            this.showModalConsent = false;
                            //if(response.data.origin === "Portal Admisiones") {
                            if (response.data.level_consent === "587030001") {
                                //no tiene aceptado los consentimientos
                                this.showModalLoading = false;
                                this.showModalConsent = true;
                                this.$refs.modal1Consent.openModal();
                            }
                            else {
                                this.getChildren(true);
                            }
                            //}
                            //else{
                            // viene de otra fuente
                            //this.showModalConsent = true;
                            //}
                        }
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            modalConsentClose() {
                this.getChildren(true);
            },

            modalErrorClose() { this.serviceError = false },
            modalLogoutClose() { this.requireLogout = false },

            getChildren(value) {

                this.axios.get(`${this.enviroments.urlDev}/Children?token=${this.accessToken}`).then(response => {
                    if ((response.data === null) || (response.data === '')) {
                        console.log("ERROR:: response.data empty");
                        this.serviceError = true;
                        this.serviceErrorMsg = "ERROR:: GET CHILDREN response.data empty";
                    }
                    else if ((response.data.status === null) || (response.data.status === '')) {
                        console.log("ERROR:: response.data.status empty");
                        this.serviceError = true;
                        this.serviceErrorMsg = "ERROR:: GET CHILDREN response.data.status empty";
                    }
                    else if ((response.data.students === null) || (response.data.students === '')) {
                        console.log("ERROR:: response.data.students empty");
                        this.serviceError = true;
                        this.serviceErrorMsg = "ERROR:: GET CHILDREN response.data.students empty";
                    }
                    else {
                        this.showModalLoading = false;
                        if (response.data.status === 1) {
                            this.serviceError = true;
                            this.serviceErrorMsg = response.data.message;
                            console.log(response.data.message);
                        } else if (response.data.status === 2) {
                            this.expireToken = true;
                        }
                        else {

                            if (response.data.students.length !== 0) {
                                this.showModalChild = value;
                                this.childs = response.data.students;
                            } else {
                                this.$router.push('basic-information');
                                this.step.firstStep = true;
                            }
                        }
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
            goHome() {
                if (!this.account) { this.$router.push('/signin'); }
                else { this.$router.push('/child'); }
            }
        }
    };
</script>
<style scoped>
</style>

