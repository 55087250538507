<template>
  <div>
    <footer>
		<div class="container">
		<div id="footer-outer" data-cols="5" data-custom-color="true" data-disable-copyright="false" data-matching-section-color="true" data-copyright-line="true" data-using-bg-img="false" data-bg-img-overlay="0.8" data-full-width="false" data-using-widget-area="true" data-link-hover="underline">
			<div id="footer-widgets" data-has-widgets="true" data-cols="5">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-4 footer-first-col">
          <!-- Footer widget area 1 -->
            <div id="media_image-2" class="widget widget_media_image">
              <img width="380" height="57" src="https://theglobalcollege.com/wp-content/uploads/2021/02/logo-TGC_blanco.png" class="image wp-image-8574  attachment-full size-full" alt="" loading="lazy" style="max-width: 100%; height: auto;" srcset="https://theglobalcollege.com/wp-content/uploads/2021/02/logo-TGC_blanco.png 380w, https://theglobalcollege.com/wp-content/uploads/2021/02/logo-TGC_blanco-300x45.png 300w" sizes="(max-width: 380px) 100vw, 380px">
            </div>
            <div id="custom_html-7" class="widget_text widget widget_custom_html">
              <div class="textwidget custom-html-widget">
                <p class="footer-more-info">{{$t('footer.moreInfo')}}</p> 
                <a href="mailto:admissions@theglobalcollege.com" class="mail-info">admissions@theglobalcollege.com</a>
              </div>
            </div>
<!--            <div id="custom_html-14" class="widget_text widget widget_custom_html">
              <div class="textwidget custom-html-widget">
                <p class="footer-disclaimer">Actualmente colegio solicitante.</p>
              </div>
            </div>-->		
          </div>
          <!--/span_3-->
          <div class="col-12 col-md-6 col-lg-3 col-menu">
            <!-- Footer widget area 2 -->
            <div id="custom_html-8" class="widget_text widget widget_custom_html">
              <div class="textwidget custom-html-widget">
                <h4 class="footer-subtitle">{{$t('footer.menu1.title')}}</h4>
                <a v-bind:href="$t('footer.menu1.url1')">{{$t('footer.menu1.item1')}}</a><br>
                <a v-bind:href="$t('footer.menu1.url2')">{{$t('footer.menu1.item2')}}</a><br>
                <a v-bind:href="$t('footer.menu1.url3')">{{$t('footer.menu1.item3')}}</a><br>
                <a v-bind:href="$t('footer.menu1.url4')">{{$t('footer.menu1.item4')}}</a><br>
                <a v-bind:href="$t('footer.menu1.url5')">{{$t('footer.menu1.item5')}}</a>

              </div>
            </div>								
          </div>
          <div class="col-12 col-md-6 col-lg-3 col-menu">
          <!-- Footer widget area 3 -->
            <div id="custom_html-9" class="widget_text widget widget_custom_html">
              <div class="textwidget custom-html-widget">
                <h4 class="footer-subtitle">{{$t('footer.menu2.title')}}</h4>
                <a v-bind:href="$t('footer.menu2.url1')">{{$t('footer.menu2.item1')}}</a><br>
                <a v-bind:href="$t('footer.menu2.url2')">{{$t('footer.menu2.item2')}}</a><br>
                <a v-bind:href="$t('footer.menu2.url3')">{{$t('footer.menu2.item3')}}</a><br>
                <a v-bind:href="$t('footer.menu2.url4')">{{$t('footer.menu2.item4')}}</a><br>
                <a v-bind:href="$t('footer.menu2.url5')">{{$t('footer.menu2.item5')}}</a>
              </div>
            </div>									
          </div><!--/span_3-->
          <div class="col-12 col-md-6 col-lg-2 col-menu">
          <!-- Footer widget area 4 -->
              <div id="custom_html-10" class="widget_text widget widget_custom_html">
                <div class="textwidget custom-html-widget">
                  <h4  class="footer-subtitle">{{$t('footer.menu3.title')}}</h4>
                  <a v-bind:href="$t('footer.menu3.url1')">{{$t('footer.menu3.item1')}}</a><br>
                  <a v-bind:href="$t('footer.menu3.url2')">{{$t('footer.menu3.item2')}}</a><br>
                  <a v-bind:href="$t('footer.menu3.url3')">{{$t('footer.menu3.item3')}}</a><br>
                  <a v-bind:href="$t('footer.menu3.url4')">{{$t('footer.menu3.item4')}}</a><br>
                  <a v-bind:href="$t('footer.menu3.url5')">{{$t('footer.menu3.item5')}}</a><br>
                  <a v-bind:href="$t('footer.menu3.url6')">{{$t('footer.menu3.item6')}}</a><br>
                  <a v-bind:href="$t('footer.menu3.url7')">{{$t('footer.menu3.item7')}}</a>
                </div>
              </div>
                <div id="custom_html-12" class="widget_text widget widget_custom_html">
                  <div class="textwidget custom-html-widget aviso-legal">
                    <p style="" class="footer-little-links">
                      <a v-bind:href="$t('footer.policy.url1')">{{$t('footer.policy.item1')}}</a>
                      | <a v-bind:href="$t('footer.policy.url2')">{{$t('footer.policy.item2')}}</a>
                      | <a v-bind:href="$t('footer.policy.url3')">{{$t('footer.policy.item3')}}</a>
                    </p>
                  </div>
                </div>
            </div>			
            </div><!--/row-->			
          </div><!--/container-->
      </div><!--/footer-widgets-->
    </div>
    <div class="container-fluid"> 
      <div class="row footer-copyright" id="copyright" data-layout="default">
        <div class="container"> 
          <div class="row"> 
          <p class="col"> 2021 © All rights reserved. </p>
            </div>
          </div>
      </div><!--/span_5-->
    </div>
      <!--/container-->
    </footer>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
};
</script>
<style scoped>
</style>
