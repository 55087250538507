<template>
    <div v-if="showModal" id="service_error" style="display:block;z-index:9999" class="modal fade" :class="serviceError?'show':'in'">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <h5 v-if="titleError" class="modal-title">The online application portal is temporarily unavailable</h5>
                    <h5 v-else class="modal-title">{{$t('modalServiceError.title')}}</h5>
                    <div class="divider-wrap col-12">
                        <div class="divider-middle mx-auto"></div>
                    </div>
                    <p class="modal-text mx-auto">{{literal}}</p>
                    <img src="@/assets/img/icon-error.svg" alt="" class="mx-auto icon-modal">
                    <div class="col text-center btn-modal-expired">
                        <!-- <button type="button" class="rounded-pill btn-lg btn-primary mb-2 btn-back col-md-5">Volver al paso anterior</button> -->
                        <button @click="closeModalServiceError()" type="button" class="btn btn-primary rounded-pill btn-acceder btn-acceder-home">{{$t('modalServiceError.button')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ModalServiceError',
        components: {
        },
        props: {
            titleError: Boolean,
            literal: String,
            serviceError: Boolean
        },
        data() {
            return {
                showModal: true
            }
        },
        computed: {
        },
        watch: {
        },
        mounted() {
            this.showModal = this.serviceError;
            if (this.showModal) document.body.style.overflowY = "hidden";
        },
        methods: {
            closeModalServiceError() {
                document.body.style.overflowY = "auto";
                this.showModal = false;
                this.$emit('closeModalError', true);

            }
        }
    }
</script>

<style>
    .modal {
        display: inherit;
    }
</style>