<template>
    <div id="loading" style="display:block;" class="modal fade show">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <h5 class="modal-title">{{$t('modalLoading.title')}}</h5>
                    <div class="divider-wrap col-12">
                        <div class="divider-middle mx-auto"></div>
                    </div>
                    <p class="modal-text mx-auto">{{$t('modalLoading.subtitle')}}</p>
                    <img src="@/assets/img/icono_cargando.svg" alt="" class="mx-auto icon-modal spinning">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: 'ModalLoading',
        components: {
        },
        props: {
            showModalLoading: Boolean
        },
        data() {
            return {
                showModal: true,
            }
        },
        computed: {
            ...mapState([
                'msalConfig',
                'enviroments',
            ]),
        },
        watch: {
        },
        mounted() {
            this.showModal = this.showModalLoading;
            if (this.showModal) document.body.style.overflowY = "hidden";
        },
        methods: {
            closeModalLoading() {
                this.showModal = false;
                document.body.style.overflowY = "auto";
            }
        }
    }
</script>

<style>
    .modal {
        display: inherit;
    }
</style>