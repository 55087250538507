<template>
  <div id="expire_token" style="display:block;" class="modal fade show">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <h5 class="modal-title">{{$t('modalExpireToken.title')}}</h5>
          <div class="divider-wrap col-12">
            <div class="divider-middle mx-auto"></div>
          </div>
          <p class="modal-text mx-auto">{{$t('modalExpireToken.subtitle')}}</p>
          <img src="@/assets/img/icono-watch.svg" alt="" class="mx-auto icon-modal">
          <div class="col text-center btn-modal-expired">
            <button @click="closeModalExpireToken()" type="button" class="btn btn-primary rounded-pill btn-acceder btn-acceder-home">{{$t('modalExpireToken.button')}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'ModalExpireToken',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState([
      'msalConfig',
      'enviroments',
      'BasicInformation',
      'AcademicResults',
      'VideoPresentation',
      'RecommendationsTeamworks'
    ]),
  },
  watch: {
  },
  mounted() {
    document.body.style.overflowY = "hidden";
  },
  methods: {
    closeModalExpireToken() {
      const modalExpireToken = document.querySelector('#expire_token');
      modalExpireToken.style.display = 'none';
      document.body.style.overflowY = "auto";
      this.$router.push('/');
      this.$store.commit('msalConfig/resetData');
    }
  }
}
</script>

<style>
.modal {
  display: inherit;
}
</style>