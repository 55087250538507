<template>
  <ModalExpireToken v-if="expireToken"/>
  <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" />

  <div v-if="showModal" id="expire_token" style="display:block;" class="modal fade show">
    <div class="modal-dialog-broad" role="document">
      <div class="modal-content">
        <div class="modal-body-broad2">
          <h5 class="modal-title">{{$t('modalSelectChild.title')}}</h5>
          <div class="divider-wrap col-12">
            <div class="divider-middle mx-auto"></div>
          </div>
          <p class="modal-text mx-auto">{{$t('modalSelectChild.subtitle')}}</p>
          <img src="@/assets/img/icono_hijo.svg" alt="" class="mx-auto icon-modal">

          <div class="col-12 text-center bottom-zone">
          <form>
          <div class="col-12 col-md-6 offset-md-3 offset-xl-4 col-xl-4" style="background">
          
            <select v-model="idAdmission" class="form-control" @change="switchView($event, $event.target.selectedIndex)">
              <option v-for="(child, index) in childs" :key="index" :value="child.admissionid">{{child.firstname}} {{child.lastname}} ({{child.academic_year.name}})</option>
            </select>
            <div class="tooltip-error" v-if="selectChildRequired"> 
              <div class="arrow"></div>
              <span class="tooltip-text">{{$t('modalSelectChild.required')}}</span>
            </div>
          </div>

          <div class="col text-center">
            <button @click="submitSelectChild()" type="button" class="btn btn-primary rounded-pill btn-acceder btn-modal-child">{{$t('modalSelectChild.buttonContinue')}}</button>
            <hr>
          </div>
          <div class="col text-center btn-modal-child-cont">
                 <a @click="addNewChild()" class="btn btn-primary btn-formulario btn-modal-child-2" > 
                 {{$t('modalSelectChild.buttonRegister')}}
                </a>
          </div>
          </form>


          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import ModalExpireToken from '@/components/ModalExpireToken.vue'
import ModalServiceError from '@/components/ModalServiceError.vue'


export default {
  name: 'ModalSelectChild',
  components: {
    ModalExpireToken,
    ModalServiceError
  },
  props: {
    childs: [],
    showModalChild: Boolean
  },
  data() {
    return {
      showModal: true,
      selectChild: null,
      selectChildRequired: false,
      idAdmission: null,
      expireToken: false,
      serviceError: false,
      selectedIndex: 0
    }
  },
  computed: {
    ...mapState([
      'enviroments',
      'idAdmission',
      'step'
    ]),
    validateSelectChild() {
      return this.selectChild;
    },
  },
  watch: {
    validateSelectChild(newValue) {
      if(newValue) {
        this.selectChildRequired = false;
      } else {
        this.selectChildRequired = true;
      }
    }
  },
  mounted() {
    document.querySelector('.carousel').classList.add('hidden');
 //   document.querySelector('.carousel_2').classList.add('hidden');

      if ((this.childs.length === 0 ) || (this.childs[0].admissionid === null) || (this.childs[0].admissionid === '') )
        {
            console.log ("ERROR:: SELECT CHILD this.childs empty");
            this.serviceError = true;
            this.serviceErrorMsg =  "ERROR:: SELECT CHILD this.childs empty";
        }
        else
        {
          this.idAdmission = this.childs[0].admissionid;
          this.showModal = this.showModalChild;
          if(this.showModal) document.body.style.overflowY = "hidden";
        }
  },
  methods: {
    submitSelectChild() {      

      if (this.idAdmission === null) {
        this.selectChildRequired = true;
        return
      }
      this.$store.commit('setIdAdmission', this.idAdmission);
      document.body.style.overflowY = "auto";
      this.showModal = false;
      
      this.step.firstStep = true;

      this.step.step1PhotoCompleted = this.childs[this.selectedIndex].step1PhotoCompleted;

      switch (this.childs[this.selectedIndex].step_admission)
      {
        case "step2":
                this.$router.push('/academic-results');
                this.step.step_admission = 2;
                this.step.step1Completed = true;
                this.step.step2Completed = false;
                this.step.step3Completed = false;
                this.step.step4Completed = false;
                break;
        case "step3":
                this.$router.push('/video-presentation');
                this.step.step_admission = 3;
                this.step.step1Completed = true;
                this.step.step2Completed = true;
                this.step.step3Completed = false;
                this.step.step4Completed = false;
                break;
        case "step4":
                this.$router.push('/recommendations-teamworks');
                this.step.step_admission = 4;
                this.step.step1Completed = true;
                this.step.step2Completed = true;
                this.step.step3Completed = true;
                this.step.step4Completed = false;
                break;        
        case "step5":
                this.$router.push('/payment-fee');
                this.step.step_admission = 5;
                this.step.step1Completed = true;
                this.step.step2Completed = true;
                this.step.step3Completed = true;
                this.step.step4Completed = true;
                break;
         case "step6":
                this.$router.push('/payment-fee?status=OK');
                this.step.step_admission = 5;
                this.step.step1Completed = true;
                this.step.step2Completed = true;
                this.step.step3Completed = true;
                this.step.step4Completed = true;
                break;
        default: 
                this.$router.push('/basic-information');
                this.step.step1Completed = false;
                this.step.step2Completed = false;
                this.step.step3Completed = false;
                this.step.step4Completed = false;
      }
      
    },
    closeModalExpireToken() {
      this.$router.push('/');
      this.$store.commit('msalConfig/resetData');
    },
    modalErrorClose()
    {this.serviceError=false},
    addNewChild() {
        document.body.style.overflowY = "auto";
        this.showModal = false;
        this.idAdmission = null;
        this.$store.commit('setIdAdmission', this.idAdmission);

        this.step.firstStep = true;        
        this.$router.push('/basic-information');
        this.step.step1Completed = false;
        this.step.step2Completed = false;
        this.step.step3Completed = false;
        this.step.step4Completed = false;

    },
    switchView: function(event, selectedIndexN) {
      this.selectedIndex = selectedIndexN;
    }
  }
}
</script>

<style>
.fade:not(.show) {
  display: none;
}
.fade.show {
  display: block;
}
</style>